import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { auth, provider, db } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "../../features/userSlice";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";
import { OpenSeaCollectionType } from "../../types/OpenSeaCollectionType";
import { OpenSeaCollectionTable } from "../organisms/OpenSeaCollectionTable";
import { OpenSeaCollectionTableCreator } from "../organisms/OpenSeaCollectionTableCreator";

export const Index = () => {
  const user: any = useSelector(selectUser);
  const history = useHistory();
  const [openSeaCollections, setOpenSeaCollections] =
    useState<Array<OpenSeaCollectionType> | null>(null);

  const signInGoogle = () => {
    auth.signInWithPopup(provider).then(() => {
      history.push("/mypage");
    });
  };

  const registerCollection = () => {
    if (user.uid) {
      history.push("/mypage");
    } else {
      signInGoogle();
    }
  };

  useEffect(() => {
    const unSub = db.collection("OpenSeaCollections").onSnapshot((snapshot) =>
      setOpenSeaCollections(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          userId: doc.data().userId,
          collectionUrl: doc.data().collectionUrl,
          collectionName: doc.data().collectionName,
          collectionDescription: doc.data().collectionDescription,
          items: doc.data().items,
          owners: doc.data().owners,
          floorPrice: doc.data().floorPrice,
          volumeTraded: doc.data().volumeTraded,
          tradeRate: doc.data().tradeRate,
          averagePrice: doc.data().averagePrice,
          bargainRate: doc.data().bargainRate,
          twitterUrl: doc.data().twitterUrl,
        }))
      )
    );
    return () => {
      unSub();
    };
  }, []);

  return (
    <div>
      <Box
        m={5}
        px={4}
        py={2}
        mb={0}
        bg="gray.50"
        borderWidth="1px"
        borderRadius="lg"
      >
        <Flex>
          <Center>
            <p>
              NFTアーティストの方へ👉あなたのOpenSeaコレクションを登録しよう
            </p>
          </Center>
          <Spacer />
          <Box>
            <Button
              colorScheme="pink"
              size="sm"
              bg={"pink.400"}
              _hover={{
                bg: "pink.300",
              }}
              onClick={registerCollection}
            >
              登録する
            </Button>
          </Box>
        </Flex>
      </Box>
      <SimpleGrid minChildWidth="350px" spacing={6} m={5}>
        <OpenSeaCollectionTableCreator
          urlPath="trands"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="tradeRate"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="averagePrice"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="bargainRate"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="items"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="owners"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="floorPrice"
          openSeaCollections={openSeaCollections}
        />
        <OpenSeaCollectionTableCreator
          urlPath="volumeTraded"
          openSeaCollections={openSeaCollections}
        />
      </SimpleGrid>
    </div>
  );
};
