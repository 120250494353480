import React, { ReactNode } from "react";
import Footer from "../organisms/layout/Footer";
import { Header } from "../organisms/layout/Header";
import styled from "styled-components";

type Props = {
  children: ReactNode;
};

export const HeaderLayout = (props: Props) => {
  return (
    <Wrapper>
      <Header />
      <Main>{props.children}</Main>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Main = styled.div`
  flex: 1;
`;
