import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import styled from "styled-components";
import { pc, sp, tab } from "../../../style/media";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "../../../features/userSlice";
import { auth, provider, storage } from "../../../firebase";

export const Header = () => {
  const history = useHistory();
  const user: any = useSelector(selectUser);
  const { isOpen, onToggle } = useDisclosure();

  const signInGoogle = async () => {
    await auth.signInWithPopup(provider);
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex flex={{ base: 1 }} justify={{ base: "start", md: "start" }}>
          <Link to="/">
            <Text
              textAlign={useBreakpointValue({ base: "start", md: "start" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
              fontWeight="bold"
              fontSize="22px"
            >
              NFT 💎 DB{"　"}
              <StyledSpan style={{ fontWeight: "normal", fontSize: "16px" }}>
                今買うべきNFTがわかる、NFTコレクションデータベース（β版）
              </StyledSpan>
            </Text>
          </Link>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={{ base: "right" }}
          direction={"row"}
          spacing={6}
        >
          {user.uid ? (
            <Link to="/mypage">
              <Button
                display={{ base: "inline-flex", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={"pink.400"}
                href={"#"}
                _hover={{
                  bg: "pink.300",
                }}
              >
                マイページ
              </Button>
            </Link>
          ) : (
            <Button
              display={{ base: "inline-flex", md: "inline-flex" }}
              fontSize={"sm"}
              fontWeight={600}
              color={"white"}
              bg={"pink.400"}
              href={"#"}
              _hover={{
                bg: "pink.300",
              }}
              onClick={signInGoogle}
            >
              ログイン / 新規登録
            </Button>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

const StyledSpan = styled.span`
  ${sp`
        display: none;
    `}
`;
