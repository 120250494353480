import React, { useState, useEffect } from "react";
import {
  Box,
  Center,
  Flex,
  Grid,
  Image,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "../../features/userSlice";
import firebase from "firebase/app";
import { auth, provider, storage, db } from "../../firebase";
import { useToast } from "@chakra-ui/react";
import dummyIcon from "../../images/dummyIcon.png";

export const OpenSeaCollectionRegisterForm = () => {
  const user = useSelector(selectUser);
  const toast = useToast();

  const [id, setId] = useState("");

  const [collectionUrl, setCollectionUrl] = useState("");
  const handleCollectionUrlChange = (event: any) =>
    setCollectionUrl(event.target.value);

  const [collectionName, setCollectionName] = useState("");
  const handleCollectionNameChange = (event: any) =>
    setCollectionName(event.target.value);

  const [collectionDescription, setCollectionDescription] = useState("");
  const handleCollectionDescriptionChange = (event: any) =>
    setCollectionDescription(event.target.value);

  const [twitterUrl, setTwitterUrl] = useState("");
  const handleTwitterUrlChange = (event: any) =>
    setTwitterUrl(event.target.value);

  const [items, setItems] = useState(0);
  const handleItemsChange = (event: any) => setItems(event.target.value);

  const [owners, setOwners] = useState(0);
  const handleOwnersChange = (event: any) => setOwners(event.target.value);

  const [floorPrice, setFloorPrice] = useState(0);
  const handleFloorPriceChange = (event: any) =>
    setFloorPrice(event.target.value);

  const [volumeTraded, setVolumeTraded] = useState(0);
  const handleVolumeTradedChange = (event: any) =>
    setVolumeTraded(event.target.value);

  const calcTradeRate = (items: number, owners: number): number => {
    if (items == 0) {
      return Number.NaN;
    } else {
      return owners / items;
    }
  };
  const calcAveragePrice = (owners: number, volumeTraded: number): number => {
    if (owners == 0) {
      return Number.NaN;
    } else {
      return volumeTraded / owners;
    }
  };
  const calcBargainRate = (
    owners: number,
    volumeTraded: number,
    floorPrice: number
  ): number => {
    if (owners == 0) {
      return 0;
    } else {
      return volumeTraded / owners - floorPrice;
    }
  };

  useEffect(() => {
    db.collection("OpenSeaCollections")
      .where("userId", "==", user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setId(doc.id);
          setCollectionUrl(doc.data().collectionUrl);
          setCollectionName(doc.data().collectionName);
          setCollectionDescription(doc.data().collectionDescription);
          setItems(doc.data().items);
          setOwners(doc.data().owners);
          setFloorPrice(doc.data().floorPrice);
          setVolumeTraded(doc.data().volumeTraded);
          setTwitterUrl(doc.data().twitterUrl);
          storage
            .ref(`/images/${doc.id}`)
            .getDownloadURL()
            .then(function (downloadURL) {
              setImageUrl(downloadURL);
            })
            .catch(function (error) {});
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }, []);

  const showToast = () => {
    toast({
      title: "保存されました",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const register = (e: any) => {
    e.preventDefault();
    db.collection("OpenSeaCollections")
      .add({
        userId: user.uid,
        collectionUrl: collectionUrl,
        collectionName: collectionName,
        collectionDescription: collectionDescription,
        items: Number(items),
        owners: Number(owners),
        floorPrice: Number(floorPrice),
        volumeTraded: Number(volumeTraded),
        tradeRate: calcTradeRate(items, owners),
        averagePrice: calcAveragePrice(owners, volumeTraded),
        bargainRate: calcBargainRate(owners, volumeTraded, floorPrice),
        twitterUrl: twitterUrl,
      })
      .then((res: any) => {
        setId(res.id);
        uploadImage();
      });
  };

  const update = (e: any) => {
    e.preventDefault();
    db.collection("OpenSeaCollections")
      .doc(id)
      .set({
        userId: user.uid,
        collectionUrl: collectionUrl,
        collectionName: collectionName,
        collectionDescription: collectionDescription,
        items: Number(items),
        owners: Number(owners),
        floorPrice: Number(floorPrice),
        volumeTraded: Number(volumeTraded),
        tradeRate: calcTradeRate(items, owners),
        averagePrice: calcAveragePrice(owners, volumeTraded),
        bargainRate: calcBargainRate(owners, volumeTraded, floorPrice),
        twitterUrl: twitterUrl,
      })
      .then((res) => {
        uploadImage();
      });
  };

  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState("");
  const handleImage = (event: any) => {
    const image = event.target.files[0];
    setImage(image);
  };

  const uploadImage = () => {
    if (image !== undefined) {
      storage
        .ref(`/images/${id}`)
        .put(image)
        .then(function (snapshot) {
          storage
            .ref(`/images/${id}`)
            .getDownloadURL()
            .then(function (downloadURL) {
              setImageUrl(downloadURL);
              showToast();
            });
        });
    } else {
      showToast();
    }
  };

  const onFormSubmit = (e: any) => {
    if (id) {
      update(e);
    } else {
      register(e);
    }
  };

  return (
    <Box mt="5">
      <form onSubmit={onFormSubmit}>
        <Box mb="6">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Text pb="1" fontWeight="bold">
              アイコン画像
            </Text>
            {imageUrl ? (
              <Image borderRadius="full" boxSize="100px" src={imageUrl} />
            ) : (
              <Image borderRadius="full" boxSize="100px" src={dummyIcon} />
            )}
            <input type="file" onChange={handleImage} />
          </div>
        </Box>
        <Box mb="4">
          <Text fontWeight="bold">OpenSeaコレクションURL</Text>
          <Input
            value={collectionUrl}
            onChange={handleCollectionUrlChange}
            placeholder="https://opensea.io/collection/xxx"
            required
          />
        </Box>
        <Box mb="4">
          <Text fontWeight="bold">コレクションの名前</Text>
          <Input
            value={collectionName}
            onChange={handleCollectionNameChange}
            required
          />
        </Box>
        <Box mb="4">
          <Text fontWeight="bold">コレクションの説明</Text>
          <Input
            value={collectionDescription}
            onChange={handleCollectionDescriptionChange}
          />
        </Box>
        <Box mb="4">
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            <Box>
              <Text fontWeight="bold">items</Text>
              <Input value={items} onChange={handleItemsChange} required />
            </Box>
            <Box>
              <Text fontWeight="bold">owners</Text>
              <Input value={owners} onChange={handleOwnersChange} required />
            </Box>
            <Box>
              <Text fontWeight="bold">floor price</Text>
              <Input
                value={floorPrice}
                onChange={handleFloorPriceChange}
                required
              />
            </Box>
            <Box>
              <Text fontWeight="bold">volume traded</Text>
              <Input
                value={volumeTraded}
                onChange={handleVolumeTradedChange}
                required
              />
            </Box>
          </Grid>
        </Box>
        <Box mb="5">
          <Text fontSize="sm">
            OpenSeaコレクションの情報を登録してください。
            <br />
            明らかに実際と異なる情報を登録しているアカウントは削除の対象となります。
          </Text>
        </Box>
        <Box mb="5">
          <Text fontWeight="bold">TwitterアカウントURL（任意）</Text>
          <Input
            value={twitterUrl}
            onChange={handleTwitterUrlChange}
            placeholder="https://twitter.com/NFT__jp"
          />
        </Box>
        <Center>
          <Box>
            <Button type="submit" colorScheme="pink">
              保存
            </Button>
          </Box>
        </Center>
      </form>
    </Box>
  );
};
