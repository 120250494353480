import { Button } from "@chakra-ui/button";
import { Center } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import { auth, provider, storage } from "../../firebase";

export const Login = () => {
  const signInGoogle = async () => {
    await auth.signInWithPopup(provider);
  };
  return (
    <>
      <Center height="200px">
        <Button
          bg="white"
          border="1px solid #ddd"
          borderRadius="lg"
          size="lg"
          onClick={signInGoogle}
        >
          Googleでログイン
        </Button>
      </Center>
      <Center>
        <Link to="/">TOPに戻る</Link>
      </Center>
    </>
  );
};
