import { Center, Container, Flex, Heading, Text } from "@chakra-ui/layout";
import { Button, Image } from "@chakra-ui/react";
import { Box, Grid, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { OpenSeaCollectionType } from "../../types/OpenSeaCollectionType";
import { auth, provider, storage, db } from "../../firebase";
import dummyIcon from "../../images/dummyIcon.png";

export const Collection = () => {
  const { id } = useParams<any>();
  const { state } = useLocation<OpenSeaCollectionType>();

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    storage
      .ref(`/images/${id}`)
      .getDownloadURL()
      .then(function (downloadURL) {
        setImageUrl(downloadURL);
      })
      .catch(function (error) {});
  }, []);

  return (
    <Container maxW="container.md" borderWidth="1px" borderRadius="lg" my={5}>
      <Box py={5}>
        {imageUrl && (
          <Center mt={5}>
            <Image borderRadius="full" boxSize="100px" src={imageUrl} />
          </Center>
        )}
        <Center my={5}>
          <Heading>{state.collectionName}</Heading>
        </Center>
        <Center>
          <Text>{state.collectionDescription}</Text>
        </Center>
        <Center my={6}>
          <a target="_blank" href={state.collectionUrl}>
            <Button colorScheme="blue">OpenSeaのサイトに移動</Button>
          </a>
        </Center>
        {state.twitterUrl && (
          <Center my={6}>
            <a target="_blank" href={state.twitterUrl}>
              <Button bg="cyan.400" textColor="white">
                Twitter
              </Button>
            </a>
          </Center>
        )}
        <Center>
          <Text fontWeight="bold">各種データ</Text>
        </Center>
        <Grid templateColumns="repeat(4, 1fr)" gap={0} m={3}>
          <Box borderWidth="1px" p={3}>
            <Text>items</Text>
            <Heading>{state.items}</Heading>
          </Box>
          <Box borderWidth="1px" p={3}>
            <Text>owners</Text>
            <Heading>{state.owners}</Heading>
          </Box>
          <Box borderWidth="1px" p={3}>
            <Text>floor price</Text>
            <Heading>{state.floorPrice}</Heading>
          </Box>
          <Box borderWidth="1px" p={3}>
            <Text>volume traded</Text>
            <Heading>{state.volumeTraded}</Heading>
          </Box>
        </Grid>

        <Grid templateColumns="repeat(3, 1fr)" gap={0} m={5}>
          <Box borderWidth="1px" p={3}>
            <Text>売却率</Text>
            <Heading>
              {(Number(state.tradeRate) * 100).toFixed(1) + "%"}
            </Heading>
          </Box>
          <Box borderWidth="1px" p={3}>
            <Text>平均価格</Text>
            <Heading>{Number(state.averagePrice).toFixed(3)}</Heading>
          </Box>
          <Box borderWidth="1px" p={3}>
            <Text>お買い得値</Text>
            <Heading>{Number(state.bargainRate).toFixed(3)}</Heading>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};
