import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useBreakpointValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text
          textAlign={useBreakpointValue({ base: "start", md: "start" })}
          fontFamily={"heading"}
          color={useColorModeValue("gray.800", "white")}
          fontWeight="bold"
          fontSize="22px"
        >
          NFT 💎 DB
        </Text>
        <Text>© 株式会社Lister</Text>
        <a
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLScLWJUp5ocRT_83Rvau1of8NUIi1rH8xZmKMgZHdgaXWyurbA/viewform?usp=sf_link"
        >
          <Text>お問い合わせ</Text>
        </a>
        <a target="_blank" href="https://twitter.com/NFT__jp">
          <Text>公式Twitter</Text>
        </a>
      </Container>
    </Box>
  );
}
