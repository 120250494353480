import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { Box, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { OpenSeaCollectionType } from "../../types/OpenSeaCollectionType";
import { OpenSeaCollectionTable } from "../organisms/OpenSeaCollectionTable";

type Props = {
  urlPath: string;
  openSeaCollections: Array<OpenSeaCollectionType> | null;
  showAll?: boolean;
};

function shuffleArray(inputArray: Array<OpenSeaCollectionType> | null) {
  return inputArray === null
    ? null
    : inputArray.sort(() => Math.random() - 0.5);
}

export const OpenSeaCollectionTableCreator = (props: Props) => {
  const { urlPath, openSeaCollections, showAll = false } = props;
  const titleCreator = (urlPath: string) => {
    if (urlPath === "trands") return "注目のコレクション";
    if (urlPath === "tradeRate") return "売却率ランキング";
    if (urlPath === "averagePrice") return "平均価格ランキング";
    if (urlPath === "bargainRate") return "お買い得ランキング";
    if (urlPath === "items") return "NFTアイテム数ランキング";
    if (urlPath === "owners") return "売却数ランキング";
    if (urlPath === "floorPrice") return "最低価格ランキング";
    if (urlPath === "volumeTraded") return "取引高ランキング";
    return "";
  };
  const descriptionCreator = (urlPath: string) => {
    if (urlPath === "trands") return "注目のコレクションを掲載しています。";
    if (urlPath === "tradeRate")
      return "販売しているNFTアイテムのうち売却されたアイテムの比率を表しています。計算式：owners / items";
    if (urlPath === "averagePrice")
      return "NFTアイテムの平均価格です。計算式：volume traded / owners";
    if (urlPath === "bargainRate")
      return "平均価格とfloor priceの差がどのくらい大きいかを表しています。値が大きいほうがお買い得です。計算式：平均価格 - floor price";
    if (urlPath === "items")
      return "販売しているNFTアイテム数のランキングです。";
    if (urlPath === "owners") return "NFTアイテムの売却数のランキングです。";
    if (urlPath === "floorPrice")
      return "NFTアイテムの最低価格のランキングです。";
    if (urlPath === "volumeTraded")
      return "NFTアイテムがどれだけ売れているかのランキングです。";
    return "";
  };

  const collectionCreator = (
    urlPath: string,
    openSeaCollections: Array<OpenSeaCollectionType> | null
  ) => {
    if (urlPath === "trands") {
      return shuffleArray(openSeaCollections);
    }
    if (urlPath === "tradeRate") {
      return openSeaCollections === null
        ? null
        : tradeRateSorted(openSeaCollections);
    }
    if (urlPath === "averagePrice") {
      return openSeaCollections === null
        ? null
        : averagePriceSorted(openSeaCollections);
    }
    if (urlPath === "bargainRate") {
      return openSeaCollections === null
        ? null
        : bargainRateSorted(openSeaCollections);
    }
    if (urlPath === "items") {
      return openSeaCollections === null
        ? null
        : itemsSorted(openSeaCollections);
    }
    if (urlPath === "owners") {
      return openSeaCollections === null
        ? null
        : ownersSorted(openSeaCollections);
    }
    if (urlPath === "floorPrice") {
      return openSeaCollections === null
        ? null
        : floorPriceSorted(openSeaCollections);
    }
    if (urlPath === "volumeTraded") {
      return openSeaCollections === null
        ? null
        : volumeTradedSorted(openSeaCollections);
    }
    return null;
  };

  const valuesCreator = (
    urlPath: string,
    openSeaCollections: Array<OpenSeaCollectionType> | null
  ) => {
    if (urlPath === "trands") {
      return null;
    }
    if (urlPath === "tradeRate") {
      return openSeaCollections === null
        ? null
        : tradeRateSorted(openSeaCollections).map((item) => {
            return (item.tradeRate * 100).toFixed(1) + "%";
          });
    }
    if (urlPath === "averagePrice") {
      return openSeaCollections === null
        ? null
        : averagePriceSorted(openSeaCollections).map((item) => {
            return Number(item.averagePrice).toFixed(3);
          });
    }
    if (urlPath === "bargainRate") {
      return openSeaCollections === null
        ? null
        : bargainRateSorted(openSeaCollections).map((item) => {
            return Number(item.bargainRate).toFixed(3);
          });
    }
    if (urlPath === "items") {
      return openSeaCollections === null
        ? null
        : itemsSorted(openSeaCollections).map((item) => {
            return item.items.toString();
          });
    }
    if (urlPath === "owners") {
      return openSeaCollections === null
        ? null
        : ownersSorted(openSeaCollections).map((item) => {
            return item.owners.toString();
          });
    }
    if (urlPath === "floorPrice") {
      return openSeaCollections === null
        ? null
        : floorPriceSorted(openSeaCollections).map((item) => {
            return Number(item.floorPrice).toFixed(3);
          });
    }
    if (urlPath === "volumeTraded") {
      return openSeaCollections === null
        ? null
        : volumeTradedSorted(openSeaCollections).map((item) => {
            return Number(item.volumeTraded).toFixed(3);
          });
    }
    return null;
  };

  const tradeRateSorted = (
    openSeaCollections: Array<OpenSeaCollectionType>
  ) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.tradeRate)) return 1;
      if (Number.isNaN(b.tradeRate)) return -1;
      return a.tradeRate > b.tradeRate ? -1 : 1;
    });
  };

  const averagePriceSorted = (
    openSeaCollections: Array<OpenSeaCollectionType>
  ) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.averagePrice)) return 1;
      if (Number.isNaN(b.averagePrice)) return -1;
      return a.averagePrice > b.averagePrice ? -1 : 1;
    });
  };

  const bargainRateSorted = (
    openSeaCollections: Array<OpenSeaCollectionType>
  ) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.bargainRate)) return 1;
      if (Number.isNaN(b.bargainRate)) return -1;
      return a.bargainRate > b.bargainRate ? -1 : 1;
    });
  };

  const itemsSorted = (openSeaCollections: Array<OpenSeaCollectionType>) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.items)) return 1;
      if (Number.isNaN(b.items)) return -1;
      return a.items > b.items ? -1 : 1;
    });
  };

  const ownersSorted = (openSeaCollections: Array<OpenSeaCollectionType>) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.owners)) return 1;
      if (Number.isNaN(b.owners)) return -1;
      return a.owners > b.owners ? -1 : 1;
    });
  };

  const floorPriceSorted = (
    openSeaCollections: Array<OpenSeaCollectionType>
  ) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.floorPrice)) return 1;
      if (Number.isNaN(b.floorPrice)) return -1;
      return a.floorPrice > b.floorPrice ? -1 : 1;
    });
  };

  const volumeTradedSorted = (
    openSeaCollections: Array<OpenSeaCollectionType>
  ) => {
    return openSeaCollections.sort(function (
      a: OpenSeaCollectionType,
      b: OpenSeaCollectionType
    ) {
      if (Number.isNaN(a.volumeTraded)) return 1;
      if (Number.isNaN(b.volumeTraded)) return -1;
      return a.volumeTraded > b.volumeTraded ? -1 : 1;
    });
  };

  return (
    <OpenSeaCollectionTable
      title={titleCreator(urlPath)}
      description={descriptionCreator(urlPath)}
      urlPath={urlPath}
      openSeaCollections={collectionCreator(urlPath, openSeaCollections)}
      values={valuesCreator(urlPath, openSeaCollections)}
      showAll={showAll}
    />
  );
};
