import React from "react";
import { useHistory } from "react-router-dom";
import { auth, provider, storage } from "../../firebase";
import { OpenSeaCollectionRegisterForm } from "../organisms/OpenSeaCollectionRegisterForm";
import styled from "styled-components";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";

export const MyPage = () => {
  const history = useHistory();
  return (
    <Box>
      <Container mt="8" p={8} borderWidth={1} borderRadius="lg">
        <Center>
          <Heading size="lg">コレクション登録</Heading>
        </Center>
        <OpenSeaCollectionRegisterForm />
      </Container>
      <Container mt="8">
        <Center>
          <Text>複数のコレクションを登録できる機能は開発中です！</Text>
        </Center>
      </Container>
      <Container my="8">
        <Center>
          <Button
            onClick={async () => {
              await auth.signOut();
              history.go(0);
            }}
          >
            ログアウト
          </Button>
        </Center>
      </Container>
    </Box>
  );
};
