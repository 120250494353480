import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "./features/userSlice";
import { auth } from "./firebase";

import theme from "./theme/theme";
import { Router } from "./router/Router";
import { HeaderLayout } from "./components/templates/HeaderLayout";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unSub = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          login({
            uid: authUser.uid,
            photoUrl: authUser.photoURL,
            displayName: authUser.displayName,
          })
        );
      } else {
        dispatch(logout);
      }
    });
    return () => unSub();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <HeaderLayout>
          <Router />
        </HeaderLayout>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
