import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, login, logout } from "../../features/userSlice";
import { Login } from "./Login";
import { MyPage } from "./MyPage";

export const MyPageSelector = () => {
  const user: any = useSelector(selectUser);
  return <>{user.uid ? <MyPage /> : <Login />}</>;
};
