import { memo } from "react";
import { Route, Switch } from "react-router";
import { Collection } from "../components/pages/Collection";
import { Index } from "../components/pages/Index";
import { MyPageSelector } from "../components/pages/MyPageSelector";
import { Page404 } from "../components/pages/Page404";
import { Ranking } from "../components/pages/Ranking";
import { HeaderLayout } from "../components/templates/HeaderLayout";

export const Router = memo(() => {
  return (
    <Switch>
      <Route exact path="/">
        <Index />
      </Route>
      <Route path="/mypage">
        <MyPageSelector />
      </Route>
      <Route path="/collection/:id" component={Collection} />
      <Route path="/ranking/:pathname" component={Ranking} />
      <Route>
        <Page404 />
      </Route>
    </Switch>
  );
});
