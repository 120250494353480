import { Center, Container, Heading, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Box, Grid, Input } from "@chakra-ui/react";
import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { OpenSeaCollectionType } from "../../types/OpenSeaCollectionType";
import { OpenSeaCollectionTable } from "../organisms/OpenSeaCollectionTable";
import { OpenSeaCollectionTableCreator } from "../organisms/OpenSeaCollectionTableCreator";

export const Ranking = () => {
  const { pathname } = useParams<any>();
  const { state } = useLocation<Array<OpenSeaCollectionType>>();
  const openSeaCollections = state;
  const urlPath = pathname;
  return (
    <Container>
      <Box m={5}>
        <OpenSeaCollectionTableCreator
          urlPath={urlPath}
          openSeaCollections={openSeaCollections}
          showAll={true}
        />
      </Box>
    </Container>
  );
};
