import React from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Skeleton,
  Spacer,
  Text,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { OpenSeaCollectionType } from "../../types/OpenSeaCollectionType";
import styled from "styled-components";

type Props = {
  title: string;
  description: string;
  urlPath: string;
  openSeaCollections: Array<OpenSeaCollectionType> | null;
  values: Array<string> | null;
  showAll: boolean;
};

export const OpenSeaCollectionTable = (props: Props) => {
  const { title, description, urlPath, openSeaCollections, values, showAll } =
    props;

  const sliceCollection = (
    collection: Array<OpenSeaCollectionType>,
    showAll: boolean
  ) => {
    if (showAll) {
      return collection;
    }
    return collection.slice(0, 10);
  };

  return (
    <Box w="100%">
      <Box borderWidth="1px" borderRadius="lg">
        <Box p={4} bg="gray.50">
          <Flex>
            <Box>
              <Text fontWeight="bold">{title}</Text>
            </Box>
            <Spacer />
            <Box>
              <Popover>
                <PopoverTrigger>
                  <Button size="xs" bg="gray.50">
                    <QuestionIcon color="gray.600" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{title}</PopoverHeader>
                  <PopoverBody>{description}</PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
        </Box>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Name</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {openSeaCollections === null ? (
              <>
                {[...Array(10)].map((v, index) => (
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td></Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {sliceCollection(openSeaCollections, showAll).map(
                  (openSeaCollection, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <StyledTd>
                        <Link
                          to={{
                            pathname: `/collection/${openSeaCollection.id}`,
                            state: openSeaCollection,
                          }}
                        >
                          <div>{openSeaCollection.collectionName}</div>
                        </Link>
                      </StyledTd>
                      <Td>{values === null ? "" : values[index]}</Td>
                    </Tr>
                  )
                )}
              </>
            )}
          </Tbody>
        </Table>
      </Box>
      {openSeaCollections === null || showAll ? (
        <></>
      ) : (
        <Box px={4} py={2}>
          <Link
            to={{ pathname: `/ranking/${urlPath}`, state: openSeaCollections }}
          >
            →すべて表示
          </Link>
        </Box>
      )}
    </Box>
  );
};

const StyledTd = styled(Td)`
  &:hover {
    background-color: #edfdfd;
  }
`;
